.chart {
  height: 65vh;
  position: relative;
  top: 7vh;
  left: -3vw;
}

.title {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3em;
  color: #707070;
  z-index: 1;
}
