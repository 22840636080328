a {
  text-decoration: none;
  color: blue;
}

em {
  font-style: italic;
}

.authors {
  flex: 2;
  margin-right: 1em;
}

.info {
  flex: 5;
}

.unpub {
  display: flex;
  flex-direction: row;
  margin: 1.5em 0;
  text-align: left;
}

.title {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3em;
  color: #707070;
}

.year {
  flex: 1;
  margin-left: 1em;
}

@media only screen and (max-width: 600px) {
  .year {
    margin-left: 0;
    margin-right: 1em;
  }
}

