section {
  color: #707070;
}

#list {
  display: none; /* CHANGE TO `BLOCK` IN JS */
  position: relative;
  top: 0;
}

#minimize {
  position: relative;
  top: 1em;
  width: 100%;
  font-size: 0.75em;
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(112, 112, 112, 0.5);
  z-index: 2;
}

.details {
  width: 100%;
  height: fit-content;
}

.explanation {
  display: inline-block;
  width: 60%;
  height: 100%;
}

.explanation p {
  padding: 0 1em;
  width: 90%;
  text-align: justify;
  line-height: 1.5em;
}

.fa-minus-square {
  vertical-align: middle;
}

.language_list {
  display: block;
}

.language_list a {
  color: #707070;
}

.language_list li {
  display: inline-block;
  margin: 0 auto;
  padding: 0.65em;
}

.language_list li:hover {
  position: relative;
  bottom: 0.2em;
  text-shadow: #dbdbdb 0 0.2em 0.2em;
  transition: 2ms;
}

.vowels {
  position: relative;
}

.charts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 30px;
  position: absolute;
  top: 110%;
  right: 0;
  width: 88vw;
  padding-bottom: 10vh;
}

.charts::after {
  content: "";
  flex: 1; /* Set to number of "blank tiles" on last row of charts */
}

.chart_list p {
  position: relative;
  top: 2em;
  width: max-content;
  height: 3em;
  margin: 0 auto;
}

.chart_title {
  text-align: center;
  padding: 0.25em 0;
}

.chart_info {
  display: none;
}

.sample {
  width: 37.5%;
  vertical-align: top;
  position: relative;
  top: 1em;
}

.vowelchart {
  display: block;
  margin: auto;
  width: 90%;
}

.tile {
  height: auto;
  margin: auto;
}

@media only screen and (display-mode: fullscreen) {
  .chart_list p {
    top: 1em;
    height: 2em;
  }
}

@media only screen and (max-width: 605px) {
  .charts {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .explanation {
    width: 100%;
  }

  .sample {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .charts {
    grid-template-columns: 1fr;
  }
}
