body {
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0 5vw;
}

header {
  display: flex;
  margin-top: 5vh;
  align-items: center;
  text-align: center;
  color: #707070;
}

h1 {
  margin: 0;
  width: auto;
  font-size: 5.5rem;
  font-weight: 400;
}

h2 {
  margin: 0;
  padding-bottom: 0.25em;
  font-weight: 400;
  font-size: 3em;
  color: #707070;
  background-color: white;
  box-shadow: 0 0.4em 0.75em -0.6em #707070;
}

.nav-cv-container {
  display: flex;
  gap: 5rem;
  align-items: center;
  margin-left: auto;
}

ul {
  display: flex;
  padding: 0;
  align-content: center;
  justify-content: flex-end;
  list-style: none;
}

li {
  padding: 3vh 1em;
  font-size: 1em;
  cursor: pointer;
  width: max-content;
}

b {
  font-weight: 700;
}

#face {
  height: min(60vh, 60vw);
  width: min(60vh, 60vw);
  box-shadow: 1.75em 1.75em 0.5em rgba(0, 0, 0, 0.16);
  border: 5px solid black;
}

#panel {
  height: 61vh;
  position: relative;
  flex: 1;
  min-width: 0;
}

.button:hover {
  padding-top: 2vh;
  text-shadow: #dbdbdb 0 0.2em 0.2em;
  transition: 2ms;
}

.button:active {
  padding-top: 3vh;
  text-shadow: none;
}

.content {
  display: flex;
  gap: 5vw;
  align-items: center;
  height: 80vh;
}

.cv {
  cursor: pointer;
}

#bibliography {
  flex-direction: column;
}

#bibliography li {
  margin: 0;
  width: auto;
  cursor: default;
}

@media only screen and (display-mode: fullscreen) {
  /* .name {
    flex: 4 19vw;
  } */
}

@media only screen and (max-width: 1200px) {
  li {
    padding-left: 1.25em;
  }

  .name {
    flex: 10;
    padding-right: 4.5vw;
    padding-left: 4.5vw;
  }
}

@media only screen and (max-width: 1000px) {
  header {
    display: block;
  }

  ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .nav-cv-container {
    flex-direction: column;
    gap: 0;
  }

  .content {
    display: block;
    margin-top: 7.5vh;
  }

  .face-container {
    display: none;
  }
}
