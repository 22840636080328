.authors {
  flex: 2;
  margin-right: 2em;
}

.info {
  flex: 5;
}

cite {
  font-style: normal;
}

.info:last-child {
  font-style: italic;
}

.no-italic {
  font-style: normal;
}

.topic {
  display: flex;
  flex-direction: row;
  margin: 1.5em 0;
  text-align: left;
}

.year {
  flex: 1;
  margin-left: 1em;
}

@media only screen and (max-width: 600px) {
  .year {
    margin-left: 0;
    margin-right: 1em;
  }
}
